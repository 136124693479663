.btn {
  text-align: center;
  display: inline-block;
  text-decoration: none;
  height: 30px;
  text-align: center;
  line-height: 30px;
  text-transform: uppercase;
  border-radius: 4px;
  line-height: 10px;
  padding: 9px;
  font-size: 10px;
  &:hover {
    text-decoration: none;
  }
  &--big {
    width: 124px;
    height: 40px;
    padding: 14px 5px;
    font: normal normal bold 10px/12px Open Sans;
  }
  &--biggest {
    width: 220px;
    height: 40px;
    padding: 14px 5px;
    font: normal normal bold 10px/12px Open Sans;
  }
  &.focus, &:focus {
    outline: none;
    color: inherit;
  }
  .ico {
    margin-right: 5px;
  }
  &--primary {
    background: $red;
    color: #fff;
    .ico {
      color: #fff;
      &:before {
        display: inline-block;
      }
    }
    &:hover {
      color: #fff;
    }
  }
  &--border {
    background: #ffffff;
    color: #999999;
    font-weight: 700;
    border: 2px #999999 solid;
    .ico {
      color: #999999;
      &:before {
        display: inline-block;
      }
    }
    &:hover {
      color: #999999;
    }
  }
  &--add {
    .ico {
      &:before {
        display: inline-block;
      }
    }
  }
  &--stats {
    .ico {
      font-size: 10px;
      &:before {
        display: inline-block;
      }
    }
  }

}

.to-overview {
    margin-top: 15px;
}
