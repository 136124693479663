//@import '../../node_modules/bootstrap/scss/bootstrap-grid';
@import '~bootstrap';
@import '~reset-css';

body {
    background: #DDDDDD;
}

$orange: #C59700;
$yellow: #DFD809;
$yellow-green: #E8FF09;
$red: #DF002A;
$salad: #53DF09;
$green: #167400;
$blue: #0A1CBC;
$bright-orange: #FFB300;
$pink: #BC0A81;
//
$oekv-gray-100: #DDDDDD;
$oekv-gray-200: #CCCCCC;
$oekv-gray-300: #B3B3B3;
$oekv-gray-400: #999999;
$oekv-gray-500: #8D8D8D;
$oekv-gray-600: #666666;
$oekv-gray-700: #707070;
$oekv-gray-800: #707070;

$footer-top-margin: 100px;
$header-bottom-margin: 100px;
$sub-footer-height: 50px;
$footer-height: 140px;
$header-height: 100px;


// @todo TEMP??? this code to keep calculate content height depending on height of the header and footer
$content-min-height: calc(100vh - #{$footer-top-margin} - #{$header-bottom-margin} - #{$sub-footer-height} - #{$footer-height} - #{$header-height});

.content {
    min-height: $content-min-height;
}

body {
    font-size: 13px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    color: #666666;
}



h2 {
    font-size: 24px;
}

a {
    font: normal normal normal 15px/24px Open Sans;
    letter-spacing: 0px;
    color: #DF002A;
    text-decoration: none;
    &:hover {
        text-decoration: underline;
    }
}

.bold {
    font-weight: bold;
}

.info {
    font: normal normal bold 15px/24px Open Sans;
    letter-spacing: 0px;

    &--gray {
        color: #999999;
    }

    &--form {
        margin-top: 20px;
        margin-bottom: 10px;
    }

    &--red {
        color: #DF002A;
        font-weight: 700;
    }
}

.lang-link {
    font-size: 14px;
    color: #666;
    cursor: pointer;

    &.active {
        font-weight: 700;
    }
}

a:hover {
    color: #DF002A;
}

.footer-content-container {
 padding: 30px 10px;
}

.dropdown-menu {
    width: 100%;
}

.resonsive-menu-item__link {
    font-weight: bold;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 18px;
    color: #DF002A;
    letter-spacing: 0;
}

.responsive-menu-link {
    text-align: center !important;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
}


