.process-page {
    margin-bottom: 25px;
}

.process {
    &__phase {
        font-size: 23px;
        line-height: 32px;
        font-weight: bold;
        color: $oekv-gray-700;
        text-transform: uppercase;
    }

    &__ausstellung-title {
        font-size: 23px;
        display: inline-block;
        line-height: 32px;
        font-weight: 900;
        color: $red;
        margin-bottom: 10px;
    }

    &__phase-info {
        font-size: 13px;
        line-height: 24px;
        color: $oekv-gray-700;
    }
}

.sidebar {
    &__title {
        font-weight: 900;
        font-size: 16px;
        text-transform: uppercase;
        color: $oekv-gray-700;
        margin-bottom: 15px;
    }

    &__info {
        color: $oekv-gray-400;
    }

    &__content {
        margin-top: 20px;
    }

    &__images {
        display: flex;
        justify-content: space-between;
        margin: 30px 0;
    }

    &__image-card {
        margin-right: 10px;
    }
}


.anmeldung-prefill-list {
    &__item {
        display: flex;
        align-items: center;
    }

    &__arrow {
        cursor: pointer;
        font-size: 25px;
        color: #DF002A;
        margin-right: 15px;
    }

    &__info {
        font-size: 14px;
        line-height: 20px;
        color: #000;
        margin-bottom: 15px;
    }
}

.payments {
    min-height: 650px;
    margin-top: 40px;

    &__total {
        display: flex;
        align-items: center;
        height: 60px;
        justify-content: space-between;
        padding: 0 50px;
        border: 1px solid #DDDDDD;
        border-radius: 5px;
        background-color: #DDDDDD;
        margin-bottom: 50px;
    }

    &__total-label {
        color: #707070;
        font-weight: 700;
    }
}

.payment-price {
    color: #707070;
    font-size: 28px;
    font-weight: 600;
    line-height: 100%;
}

.payment-entry {
    border: 1px solid #DDDDDD;
    border-radius: 5px;
    padding: 20px 50px 20px 27px;
    margin-bottom: 15px;

    &__price-details {
        display: flex;
        justify-content: space-between;
        text-align: right;
    }

    &__date {
        font-size: 13px;
        font-weight: 700;
        color: #000;
    }

    &__name {
        font-size: 15px;
        color: #000;
    }

    &__price {
        font-size: 16px;
        color: #000000;
    }

    &__label {
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        color: #000;
        margin-right: 4px;

        &--link {
            &:hover {
                border-bottom: 2px solid #ddd;
                text-decoration: none;
                color: #000;
                padding-top: 2px;
            }
        }
    }

    &__anmeldung-count {
        width: 23px;
        display: block;
        height: 23px;
        background: #FFB300;
        color: #fff;
        border-radius: 5px;
    }
}

.anmeldung-overview {
    margin-top: 60px;

    &__title {
        font-weight: 900;
        font-size: 16px;
        text-transform: uppercase;
        color: $oekv-gray-700;
        margin-bottom: 15px;
    }

    &__info {
        font-weight: 700;
        font-size: 14px;
        color: #7F7F7F;
        margin-top: 45px;
    }

    &__buttons-wrapper {
        margin-top: 50px;
        display: flex;
        justify-content: space-between;
    }

    &__table {
        margin-top: 10px;
        min-width: 580px;

        tr {
            td {
                &:nth-child(1) {
                    text-transform: uppercase;
                    color: #707070;
                    font-size: 12px;
                    font-weight: 700;
                }

                &:nth-child(2) {
                    color: #000000;
                    font-size: 14px;
                }

                padding: 15px 10px;
            }
        }

        tr:nth-child(odd) {
            background-color: #DDDDDD4a;
        }
    }
}
