.dropdown-toggle-split {
    width: 50px;
    height: 50px;
    background: #707070;
    border-radius: 0;
    border: none;
    position: relative;

    &::after {
        content: '\ea1c';
        font-family: 'icomoon' !important;
        speak: never;
        font-style: normal;
        font-weight: normal $yellow-green;
        font-variant: normal;
        text-transform: none;
        line-height: 1;
        cursor: pointer;
        color: #c7c7c7;
        border: none;


        /* Better Font Rendering =========== */
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;


        position: absolute;
        transform: rotate(90deg);
        right: calc(50% - 6px);
        top: calc(50% - 6px);
    }
}


.btn:focus {
    box-shadow: none;
    color: #fff!important;
}

.form-control:focus {
    box-shadow: 0 0 0 0.1rem rgb(13, 110, 253 / 25%);
}

.has-error .form-error:focus {
    box-shadow: 0 0 0 0.1rem rgb(13, 110, 253 / 25%);
}

.dropdown-toggle-selection {
    border: none;
    border-radius: 0;
    height: 50px;
    width: 120px;
    float: left;
    padding: 15px;
    text-transform: uppercase;
    font-weight: 700;
    color: #666666;
    text-align: left;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 10rem;
    padding: .5rem 0;
    margin: .125rem 0 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: .25rem;
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;

    &:hover {
        text-decoration: none;
        background: #dcdcdc;
        color: #000;
    }
}


.dropdown-item {
    display: block;
    width: 100%;
    padding: .50rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #212529;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #e9ecef;
}

.btn-group.open .dropdown-toggle {
    box-shadow: none;
}
