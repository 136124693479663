.footer {
  background: #111;
  height: 100%;
  margin-top: $footer-top-margin;
  color: #999999;
  font: normal normal normal 16px/24px Open Sans;
  letter-spacing: 0;
  h2 {
    font: normal normal bold 18px/24px Open Sans;
    font-weight: bold;
    margin: 0;
  }
  .logo {
    width: 71px;
    height: 64px;
    display: block;
    background: #6d000d;
  }
  .col-lg-4 {
    display: flex;
    align-items: center;
    height: $footer-height;
  }
}

//.footer-addess {
//  text-align: center;
//}

.footer-contacts {
  text-align: right;
}


.footer-info {
  margin-left: 24px;
}

.footer-addesses {
    text-align: right;
}


.sub-footer {
  background: #707070;
  align-items: center;
  letter-spacing: 0px;
  color: #DDDDDD;
  font: normal normal normal 12px/27px Open Sans;
  .col-lg-4 {
    display: flex;
    align-items: center;
    height: 100%;
  }
  padding-top: 10px;
  padding-bottom: 10px;
}

.sub-footer-menu-wrapper {
  display: flex;
  justify-content: flex-start;
}

.sub-footer-menu {
  li {
    display: inline-block;
    margin-right: 25px;
    text-align: center;
    a {
      font-size: inherit;
      color: #DDDDDD;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

.footer-info-wrapper {
    display: flex;
}

