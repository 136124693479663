@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon/fonts/icomoon.eot?v2ldga');
  src:  url('fonts/icomoon/fonts/icomoon.eot?v2ldga#iefix') format('embedded-opentype'),
  url('fonts/icomoon/fonts/icomoon.ttf?v2ldga') format('truetype'),
  url('fonts/icomoon/fonts/icomoon.woff?v2ldga') format('woff'),
  url('fonts/icomoon/fonts/icomoon.svg?v2ldga#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-zahlungen:before {
  content: "\e900";
  color: #707070;
}
.icon-meine-anmeldungen:before {
  content: "\e901";
  color: #707070;
}
.icon-login:before {
  content: "\e902";
  color: #707070;
}
.icon-place:before {
  content: "\e903";
  color: #707070;
}
.icon-user:before {
  content: "\e971";
}
.icon-key:before {
  content: "\e98d";
}
.icon-carret:before {
  content: "\ea1c";
}
.icon-date:before {
  content: "\e904";
  color: #707070;
}
.icon-stats-dots:before {
  content: "\e99b";
}
.icon-cancel-circle:before {
  content: "\ea0d";
  transform: rotate(45deg);
}
.icon-edit:before {
  content: "\e909";
}
.icon-delete:before {
  content: "\e9ac";
}

.icon-checkmark:before {
  content: "\ea10";
}
.icon-arrow-left2:before {
  content: "\ea40";
}
.icon-question:before {
  content: "\ea09";
}
.icon-menu::before {
  content: "\e9bd";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-close-modal .path1:before {
  content: "\e905";
  color: rgb(241, 249, 255);
}
.icon-close-modal .path2:before {
  content: "\e906";
  margin-left: -1em;
  color: rgb(149, 152, 154);
}
.icon-close-modal .path3:before {
  content: "\e907";
  margin-left: -1em;
  color: rgb(112, 112, 112);
}

.icon-load-spinner:before {
  content: "\ea2e";
}

.icon-load-more {
  content: " ";
  display: block;
  margin-top: 10px;
  span {
    height: 2px;
    display: block;
    margin:  2px auto;
    background: $oekv-gray-400;

    &:nth-child(1) {
      width: 30px;
    }
    &:nth-child(2) {
      width: 20px;
    }
    &:nth-child(3) {
      width: 10px;
    }
  }
}
