.content-block {
  background: #fff;
  color: #999999;
  font: normal normal normal 13px/24px Open Sans;
  padding: 20px 45px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  &--centered {
    padding: 18px 70px;
    width: 800px;
    margin: 0 auto;
    border-radius: 10px 0px 0px 10px;
  }
  &--static {
    min-height: 300px;
    padding: 18px 70px;
    border-radius: 10px 0px 0px 10px;
  }
  h2 {
    margin-bottom: 16px;
    margin-top: 0;
    color: #DF002A;
    font-weight: 700;
    font: normal normal bold 32px/50px Open Sans;
    letter-spacing: 0px;
  }
  h3 {
    font: normal normal 800 23px/32px Open Sans;
    letter-spacing: 0px;
    color: #707070;
  }
  &__image {
    padding: 30px 70px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 0px 10px 10px 0px;
    min-height: 258px;
  }
}
