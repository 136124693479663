

.ausstellung-list {
  margin-top: 100px;
  &__header {
    display: flex;
    margin-bottom: 26px;
    justify-content: space-between;

  }
  &__title {
    color: #707070;
    font-size: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-weight: 900;
  }
  &__filters {
    display: flex;
    align-items: center;
  }
  &__filter-group {
    display: flex;
    align-items: center;
    margin-right: 40px;
    &:last-child {
      margin-right: 0;
    }
  }
  .filter-label {
    margin-right: 10px;
    font-size: 14px;
    font-weight: bold;
    text-transform: uppercase;
    color: #707070;
  }

  .filter-switch {
    border: 2px solid #707070;
    border-radius: 4px;
    &__item {
      display: inline-block;
      width: 125px;
      height: 50px;
      border: none;
      color: #707070;
      padding: 15px 10px;
      text-align: center;
      text-transform: uppercase;
      font-size: 13px;
      line-height: 19px;
      background: #DDDDDD;
      font-weight: bold;
      &.active {
        color: #fff;
        background: #707070;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }
}

.card__aussteller-info {
  min-height: 45px;
  background: #707070;
}

.fl-left{float: left}

.fl-right{float: right}

.event-information-user-data {
  height: 50px;
  width: 100%;
  position: relative;
  z-index: 1;
  &__content {
    height: 50px;
    position: absolute;
    width: 100%;

    padding: 10px 20px 0;
    display: flex;
    justify-content: flex-end;

    background: #707070;
    z-index: 1;
    bottom: 6px;
    border-radius: 8px;
    box-shadow: 0px 3px 6px #00000029;

  }
  &__content-anmeldungen {
    margin-right: 60px;
    display: flex;
    align-items: center;
  }
  &__content-anmeldungen-count {
    margin-left: 10px;
    width: 27px;
    font-weight: bold;
    height: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    border-radius: 50%;
    color: #000;
  }
  &__content-offen {
    display: flex;
    align-items: center;
  }
  &__content-offen-value {
    color: #fff;
    margin-left: 10px;
    font-weight: bold;
  }
}

.event-information-wrapper {
  margin-bottom: 40px;
}

.anmeldungen-available {
  background: $salad;
}
.price-available {
  color: $yellow-green;
    line-height: 1;
    padding-bottom: .3rem;
}

.card
{
  display: table;
  width: 100%;
  background-color: #fff;
  min-height: 208px;
  color: #989898;
  font-family: 'Open Sans', sans-serif;
  text-transform: uppercase;
  border-radius: 8px;
  position: relative;
  box-shadow: 0px 3px 6px #00000029;
}

.card + .card{margin-left: 2%}

.date
{
  display: table-cell;
  width: 25%;
  position: relative;
  text-align: center;
  border-right: 2px dashed #ddd;
  &__day {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 60px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #000000;
  }
  &__month {
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 22px;
    line-height: 30px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #999999;
  }
}

.date:before,
.date:after
{
  content: "";
  display: block;
  width: 30px;
  height: 30px;
  background-color: #ddd;
  position: absolute;
  top: -15px ;
  right: -15px;
  z-index: 1;
  border-radius: 50%
}

.date:after
{
  top: auto;
  bottom: -15px
}

.date time
{
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%)
}

.date time span{display: block}

//.date time span:first-child
//{
//  color: #2b2b2b;
//  font-weight: 600;
//  font-size: 250%
//}
//
//.date time span:last-child
//{
//  text-transform: uppercase;
//  font-weight: 600;
//  margin-top: -10px
//}

.card-cont
{
  display: table-cell;
  width: 75%;
  font-size: 85%;
  padding: 20px 10px 30px 30px;
}

.card-cont .time {
  font-size: 18px;
}
.card-cont .place {
  font-size: 18px;
}

.card-buttons {
  position: absolute;
  bottom: 10px;
  right: 10px;
  .btn {
    margin-right: 5px;
    &:last-child {
      margin-right: 0;
    }
  }
}

.card__event-info
{
  h3 {
    font-weight: bold;
    font-family: 'Open Sans Condensed', sans-serif;
    font-size: 24px;
    color: #DF002A;
    letter-spacing: 0;
  }
  &__details {
    text-transform: none;
    margin-top: 10px;
     > div {
       .ico {
         margin-right: 5px;
       }
      margin-bottom: 5px;
    }
  }
}

.card-cont .card__event-info
{
  display: flex;
  flex-direction: column;
  min-height: 120px;
  justify-content: space-between;
}

.event-information-user-data__content-label {
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
}

.card-cont .even-date i,
.card-cont .even-info i,
.card-cont .even-date time,
.card-cont .even-info p
{
  display: table-cell
}

.card-cont .even-date i,
.card-cont .even-info i
{
  padding: 5% 5% 0 0
}

.card-cont .even-info p
{
  padding: 30px 50px 0 0
}

.card-cont .even-date time span
{
  display: block
}

.card-link  {
    display: flex;
    //align-items: center;
    //justify-content: center;
}

//.card-cont a
//{
//  display: block;
//  text-decoration: none;
//  width: 80px;
//  height: 30px;
//  color: #fff;
//  text-align: center;
//  line-height: 30px;
//  border-radius: 2px;
//}

/*.row:last-child .card:first-child .card-cont a*/
/*{*/
/*    background-color: #037FDD*/
/*}*/

/*.row:last-child .card:last-child .card-cont a*/
/*{*/
/*    background-color: #F8504C*/
/*}*/

@media screen and (max-width: 860px)
{
  .card
  {
    display: block;
    float: none;
    width: 100%;
  }

  .card + .card{margin-left: 0}

  .card-cont .even-date,
  .card-cont .even-info
  {
    font-size: 75%
  }
}
