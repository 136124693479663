

@import './scss/general';

@import './scss/bootstrap-overrides';
@import "~react-datepicker/dist/react-datepicker.css";
@import '~react-notifications/lib/notifications.css';
@import 'react-notifications-component/dist/theme.css';
@import './scss/form';
@import './scss/modal';
@import './scss/process-page';
@import './scss/icons';
@import './scss/tickets';
@import './scss/buttons';
@import './scss/header';
@import './scss/static';
@import './scss/footer';
@import './scss/temp';
