
.input-group-text {
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 0;
    background: #707070;
    color: #fff;
    padding: 0;
    justify-content: center;
}

.form-control {
    height: 50px;
    flex: 0 0 50px;
}

.error-message {
    color: #DF002A;
    font-size: 12px;
    display: inline-block;
    width: 100%;
}


.form-element-wrapper {
    padding-bottom: 20px;

    label {
        text-transform: uppercase;
        font-weight: 600;

        &.required {
            &::after {
                padding-left: 5px;
                content: '*';
                color: red;
            }
        }
    }

}

.has-error {
    .form-control {
        background-color: #f9abb96b;
    }
    .input__checkbox {
        border-color: #ff5151!important;
    }
}

.input-group {
    margin-bottom: 5px;
    width: 100%;
    height: 100%;

    .icon {
        font-size: 18px;
    }
}

