.modal-overlay {
  background: rgba(7, 7, 7, 0.4);
  position: fixed;
  inset: 0px;
  z-index: 999;
}

.modal-close-button {
  width: 26px;
  height: 26px;
  display: flex;
  position: absolute;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  font-size: 26px;
  right: 10px;
  top: 10px;
}

.dialog-links {
  text-align: right;
  margin-bottom: 30px;
}