.header-container {
  background: #DDDDDD;
  box-shadow: 0px 3px 6px #00000029;
  height: $header-height;
  position: relative;
  margin-bottom: $header-bottom-margin;
}

.lang-links {
  min-height: 0;
}

.header {
  position: relative;
}

.logo-col {
  align-items: center;
  display: flex;
  height: $header-height;
}

.dropdown-item {
    white-space: pre-wrap;
}

.menu-col {
  height: $header-height;
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.menu-item {
  margin-right: 80px;
  align-items: center;
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  &__link {
    margin-left: 15px;
    font-size: 13px;
    color: #666;
    &:hover {
      cursor: pointer;
      text-decoration: none;
    }
  }
  &__content-additional {
    display: flex;
    font-size: 12px;
    margin-top: 5px;
    justify-content: flex-end;
    font-weight: 700;
    width: 100%;
      &--zahlungen {
        color: $orange;
    }
  }
  &__content {
    display: flex;
    align-items: center;

  }
  &__content--expandable {
    position: relative;
    &::after {
      //content: '\ea1c';
      //font-family: 'icomoon' !important;
      //speak: never;
      //font-style: normal;
      //font-weight: normal$yellow-green;
      //font-variant: normal;
      //text-transform: none;
      //line-height: 1;
      //cursor: pointer;
      //
      ///* Better Font Rendering =========== */
      //-webkit-font-smoothing: antialiased;
      //-moz-osx-font-smoothing: grayscale;
      //
      //
      //position: absolute;
      //transform: rotate(90deg);
      //right: calc(0px - 20px);
      //top: calc(50% - 6px);
    }
  }
  &:last-child {
    margin-right: 0;
  }
}



.icon {
  cursor: pointer;
  //float: left;
  font-size: 24px;
  &-login {
    font-weight: bold;
  }
}

.header .logo {
  position: absolute;
  left: -15px;
  top: 23px;
}



.headings {
  font-weight: 700;
  min-height: 40px;
  margin: 0 0 0 120px;
}
