// this file contains


#dialog {
    padding: 70px 55px 60px 55px;

    h3 {
        font: normal normal 800 23px/32px Open Sans;
        letter-spacing: 0px;
        color: #707070;
    }


}

.scroll-up-button {
    width: 39px;
    height: 39px;
    cursor: pointer;
    border-radius: 50%;
    font-size: 18px;
    position: absolute;
    right: 10px;
    top: -45px;
    color: #fff;
    background: #707070;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: rotate(90deg);
}

.react-datepicker-wrapper {
    flex: 1 1 auto;
    width: auto;
}

label {
    font-weight: 600;

    &.required {
        &::after {
            padding-left: 5px;
            content: '*';
            color: red;
        }
    }
}

.field-label {
    cursor: pointer;
    text-transform: none;

    &.required {
        &::after {
            padding-left: 5px;
            content: '*';
            color: red;
        }
    }
}

.custom-label {
    text-transform: none !important;
}


.react-datepicker-wrapper {
    position: relative;

    &:after {
        font-family: 'icomoon';
        content: "\e908";
        display: block;
        width: 18px;
        color: #666;
        position: absolute;
        height: 18px;
        font-size: 18px;
        right: 12px;
        top: 14px;
    }

    .datepicker-field {
        padding-right: 36px;
    }
}

.switch-label, .upload-label, .weitere-label {
    font-size: 16px;
    text-transform: uppercase;
    line-height: 22px;
    margin-right: 22px;
    font-weight: bold;
}

.file-input__label {
    text-transform: uppercase;
    font-weight: 600;
}

.file-input__wrapper {
    position: relative;
    display: inline-block;
    width: 100%;
    margin-bottom: 0;
}

.file-input {
    height: 48px;
    position: relative;
    width: 100%;
}

.file-input__input {
    z-index: 2;
    width: 100%;
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 48px;
    opacity: 0;
}

.file-input__element {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: 48px;
    padding: .375rem .75rem;
    line-height: 1.5;
    display: flex;
    align-items: center;
    background-color: #fff;
    border: 1px solid #ced4da;
    color: #8D8D8D;
    border-radius: .25rem;

    &::after {
        color: #495057;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 3;
        display: block;
        height: 46px;
        width: 180px;
        padding: 0 24px;
        font: normal normal normal 14px/19px Open Sans;
        line-height: 1.5;
        color: #707070;
        content: attr(data-content);;
        background-color: #DDDDDD;
        border-left: 1px solid #ced4da;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.switch-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
}


.form-actions {
    text-align: right;
}

.input__checkbox {
    color: #7F7F7F;
    margin-right: 10px;
    cursor: pointer;
}

.link-red {
    margin-right: 20px;
}


.form-control-checkbox {
    flex: 1 1 auto;
    width: 1%;
    min-width: 0;
    align-items: center;
    display: flex;
}

.login-modal {
    position: absolute;
    inset: calc(50% - 290px) 40px 40px calc(50% - 300px);
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    width: 600px;
    height: 600px;
}

.radios {
    display: flex;
    justify-content: space-around;
    padding: 0 40px;
    margin: 10px;
    flex-basis: calc(100% - 70px);

}


@-webkit-keyframes rotating /* Safari and Chrome */
{
    from {
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes rotating {
    from {
        -ms-transform: rotate(0deg);
        -moz-transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
        -o-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
}


.load-spinner {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 30px;
    height: 100%;
    margin: 0 auto;
    text-align: center;

    &__icon {
        font-size: 30px;

    }

    &__list {
        margin-top: 100px;
    }
}

.load-more {
    display: flex;
    min-width: 100px;
    margin: 0 auto;
    flex-direction: column;
    text-align: center;
    align-items: center;
    border: none;
    text-transform: uppercase;
    background: none;
    color: $oekv-gray-400;

    &__text {
        margin-top: 15px;
        font-weight: 500;
        letter-spacing: 2px;
    }

    &__icon {
        font-size: 28px;
        color: inherit;
    }

    &:hover {
        color: $oekv-gray-600;

        .icon-load-more span {
            background: $oekv-gray-600;
        }
    }
}

.radio-button {
    display: flex;
    cursor: pointer;

    &::before {
        display: block;
        content: " ";
        width: 30px;
        height: 30px;
        border: 2px solid #707070;
        background: #DDDDDD;
        border-radius: 20px;
        margin-right: 10px;
    }

    &--checked {
        &::before {
            display: block;
            content: " ";
            width: 30px;
            height: 30px;
            border: 10px solid #707070;
            background: #DDDDDD;
            border-radius: 20px;
            margin-right: 10px;
        }
    }
}


.form {
    .input-group-text {
        background: #DDDDDD;
        color: #707070;
    }
}

.thank-you-wrapper {
    max-width: 500px;
}


/** @todo remove in separate file */
.anmeldung-table {
    width: 100%;
    thead {
        tr {
            height: auto;
        }
    }
    tbody {
        tr {
            height: 70px;
        }
    }
    tr {
        padding: 5px;
        vertical-align: middle;
        border-bottom: 2px solid #DDD;

        &:nth-child(2n) {
            background-color: #f5f5f5;
        }

        th {
            color: #707070;
            font-weight: 600;
            font-size: 12px;
        }
        td {
            color: #000000;
            font-size: 15px;
            vertical-align: middle;
        }
    }
    &__cell-check {
        color: #707070;
        .icon {
            font-size: 15px;
        }
    }
    &__heading-wrapper {
        text-transform: uppercase;
    }
    &__button {
        margin-right: 5px;
        &:last-child {
            margin: 0;
        }
        &--edit {
            &::after {
                font-family: 'icomoon' !important;
                content: "\e909"
            }
        }
        &--delete {
            &::after {
                font-family: 'icomoon' !important;
                content: "\e9ac"
            }
        }
    }
    &__buttons {
        a {
            text-decoration: none;
            color: #707070;
            padding: 7px 10px;
            border-radius: 4px;
            background: #ddd;
        }
        .icon {
            font-size: 18px;
        }
    }
    &__colum-ausstellung {
        max-width: 160px;
        width: 160px;
    }
    &__colum-hundename {
        max-width: 140px;
        width: 140px;
    }
    &__colum-checkbox {
        padding-right: 15px;
    }
    &__colum--centered {
        text-align: center;
        justify-content: center;
    }
    &__filter {
        max-width: 250px;
    }
    &__filter-search-label {
        padding-bottom: 20px;
        padding-right: 20px;
        text-transform: uppercase;
    }
    &__cell-freischaltung {
        font-size: 9px;
        border-radius: 4px;
        color: #fff;
        display: flex;
        height: 15px;
        justify-content: center;
        align-items: center;
        margin-bottom: 3px;
        margin-left: 5px;

        &--wartend {
            background-color: #FFB300;
            width: 50px;
        }
        &--freigeschalten {
            background-color: #167400;
            width: 25px;

        }
        &--gesperrt {
            background-color: #740000;
            width: 50px;
        }
    }
    &__cell-geschlecht {
        display: flex;
        border-radius: 4px;
        width: 20px;
        height: 20px;
        color: white;
        justify-content: center;
        align-items: center;
        margin: 0 auto;

        &--h {
            background-color: #BC0A81;
        }
        &--r {
            background-color: #0A1CBC;
        }
    }
}

.pager {
    display: inline-block;
    margin-top: 15px;
    &__page {
        display: inline-block;
        margin-right: 3px;
        a {
            display: inline-block;
            width: 25px;
            height: 25px;
            color: #707070;
            text-align: center;
            border-radius: 4px;
            font-weight: bold;
            &.active {
                color: #fff;
                background-color: #707070;
            }

        }
    }
}

.anmeldung-delete {
    &__buttons-wrapper {
        .btn:first-child {
            margin-right: 5px;
        }
    }
    &__form {
        display: flex;
        height: 300px;
        justify-content: flex-end;
        align-items: end;
    }
}

.sort-indicator {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: baseline;
    color: #ddd;
    margin-left: 4px;
    margin-bottom: 3px;

    &__carret-top {

        width: 9px;
        height: 8px;
        &--active {
            color: #979797;
        }
        .icon {
            display: block;
            font-size: 8px;
            transform: rotate(-90deg);
            transform-origin: center;
        }
    }
    &__carret-bottom {

        width: 8px;
        height: 8px;
        &--active {
            color: #979797;
        }
        .icon {
            display: block;
            font-size: 8px;
            transform: rotate(90deg);
            transform-origin: center;
        }
    }
}

.notification {
    background: none;
    box-shadow: none;
}

.icon-link:hover {
    text-decoration: none;
}

.ausstellung-list__filters {
    flex-wrap: wrap;
}

.anmeldung-delete__info {
    padding: 30px;
    font-size: 20px;
    line-height: 1.5;
    margin-bottom: 30px;
}

.anmeldung-delete__form {
    height: 30px;
    justify-content: center;
    align-items: center;
}

.delete-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 400px !important;
}

.content-block__image {
    display: none;
}


/* Media Queryies */

@media (min-width: 2240px) {
    .container {
        width: 1670px;
        max-width: 1670px;
    }

    .filter-switch__item {
        width: 80px !important;
    }
}

@media (max-width: 2240px) {
    .ausstellung-list__header {
        display: block !important;
    }
    .ausstellung-list__title {
        margin-bottom: 15px !important;
    }
}

@media (max-width: 1450px) {
    .content-block {
        &--static {
            min-height: 370px;
        }
    }
    .container {
        max-width: 1200px;
    }

    .footer-content-container {
        padding-left: 5px !important;
        padding-right: 5px !important;
    }

    .footer-info-wrapper > img {
        width: 40px !important;
        height: 40px !important;
    }

}

.load-screen {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

.load-overlay {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9999;
    background-color: #70707070;
    width: 100%;
    height: 100vh;
    justify-content: center;
    align-items: center;
}

@media (max-width: 1200px) {
    .process-overview {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .content-block {
        padding: 20px !important;
    }

    .btn-middle {
        margin-left: 10px;
        margin-right: 10px;
    }

    .menu-item {
        margin-right: 20px !important;
    }

    .ausstellung-list__filter-group {
        margin-bottom: 10px;
        display: block !important;

    }

    .filter-btn {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        margin-top: .7rem;
    }

    .filter-switch {
        border: none !important;
    }

    .filter-switch__item {
        border: 2px solid #707070 !important;
    }
}

@media (min-width: 1001px){
    .burger-menu-item {
        display: none !important;
    }
}

@media(max-width: 1089px) {
    .headings {
        display: none !important;
    }
}

@media (max-width: 1000px) {
    .content-block--static, {
        border-radius: 10px !important;
    }

    .content-block__image {
        border-radius: 0 0 10px 10px !important;
    }

    .event-information-user-data__content-anmeldungen {
        margin-right: 20px;
    }

    .content-block--static {
        padding: 18px 25px !important;
    }

    .process-page > .row {
        display:flex;
        flex-flow: column;
    }
    .main-content-container {
        order:2;
        margin-top: 20px !important;
    }
    .registration-process-container {
        order:1;
    }

    .anmeldung-table {
        display: block;
        max-width: 100%;
        overflow-x:auto;
    }

    .menu-item {
        display: none !important;
    }

    .dropdown-toggle,
    .lang-links {
        display: block !important;
    }

    .content-block--centered {
        width: 100% !important;
        border-radius: 10px;
    }
}

@media (max-width: 767px) {
    .anmeldung-overview__table tr td:nth-child(1){
        width: 170px;
    }

    .anmeldung-overview__table {
        min-width: 0;
        display: block;
        max-width: 100%;
        overflow-x:auto;
    }
    .footer-row {
       display: block !important;
        text-align: center;
    }
    .footer-info-wrapper {
        display: block !important;
    }

    .footer-addesses,
    .footer-contacts {
        text-align: center;
        margin-top: 10px;
    }

    .footer-info-wrapper > img {
        width: 50px !important;
        height: 50px !important;
        margin-bottom: 5px;

    }

}

@media (max-width: 600px) {
    .modal-close-button {
        right: 40px;
    }

    .radios {
        display: block !important;
    }

    .radio-button {
        margin-bottom: 10px !important;
    }

    .payments__total {
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .delete-modal {
        width: 400px !important;
        top: calc(50% - 290px) !important;
        left: calc(50% - 200px) !important;
    }

    .content-block--static > h2 {
        overflow-wrap: break-word;
    }

    .login-modal {
        width: 100% !important;
        height: 600px !important;
        top: calc(50% - 290px) !important;
        left: calc(0%) !important;
    }
}

@media (max-width: 355px){
    .card {
        padding-bottom: 40px !important;
    }
    .card-buttons,
    .btn--stats, {
        display: block !important;
    }

    .event-information-user-data__content-anmeldungen {
        margin-right: 10px !important;
    }

    .card-cont {
        padding-left: 5px !important;
    }


    .payment-entry__anmeldung-count-wrapper {
        margin-bottom: 10px;
    }

}

